"use client";
import React, { useState } from "react";
import {
    FaInstagram,
    FaFacebook,
    FaWhatsappSquare,
    FaWhatsapp,
    FaMailBulk,
    FaMailchimp,
    FaVoicemail
} from "react-icons/fa";
import "./Contacto.scss";
import { Formik } from "formik";
import axios from "axios";

const Contacto = () => {
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);

  return (
    <div id="contacto" className="contact-section">
      <div className="contact-left">
        <h4>CONTACTO</h4>
        <h2>Te hablamos</h2>
        <p>Dejanos tus datos y nos pondremos en contacto a la brevedad</p>

      </div>
      <div className="contact-right">
        <div className="social-media">
          <div className="social-icon">
            <a
                href="https://www.instagram.com/estudioappi/?hl=es-la"
                target="_blank"
                rel="noopener noreferrer"
            >

              <FaInstagram className={"ani-ig"} />
            </a>
              <p>@estudioappi</p>
          </div>

            <div className="social-icon">
                <a
                    href="mailto:arquitectura.appi@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaMailBulk className={"ani-gmail"} />
                </a>
                <p>arquitectura.appi@gmail.com</p>
            </div>
            <div className="social-icon">
                <a
                    href="https://wa.me/5491159620493?text=Hola!%20Quería%20realizar%20la%20siguiente%20consulta..."
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaWhatsapp className={"ani-wpp"} />
                </a>
                <p>+5491159620493</p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
