import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Navbar.scss";
import LogoLetras from "../../../media/logoletrasblancas.webp";
import LogoLineas from "../../../media/logolineas.webp";

const NavBar = () => {
  const [clicked, setClicked] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const bgDiv = useRef(null);
  const linksActive = useRef(null);

  const handleClick = () => {
    bgDiv.current.classList.toggle("active");
    linksActive.current.classList.toggle("d-flex");
    setClicked(!clicked);
  };

  const changeBg = () => {
    if (window.scrollY > 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBg);
    return () => {
      window.removeEventListener("scroll", changeBg);
    };
  }, []);

  return (
    <nav className={navbar ? "navbar-container1" : "navbar-container2"}>
      <div className={navbar ? "navbar-bg" : "navbar-nobg"}>
        <div className="left">
          <motion.div
            className="logo"
            viewport={{ once: true }}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: { duration: 0.5, delay: 0.3 },
            }}
          >
            <a href="/">
              <img
                className="logo_time"
                src={navbar ? LogoLineas : LogoLetras}
                alt="Logo"
                title="Logo"
              />
            </a>
          </motion.div>
        </div>
        <motion.div
          className={`right ${navbar ? "dark-theme" : "light-theme"}`}
          viewport={{ once: true }}
          initial={{ opacity: 0, y: -30 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, delay: 0.3 },
          }}
        >
          <div className="links">
            <a className="nav-link" href="/#home">
              INICIO
            </a>
            <a className="nav-link" href="/#servicios">
              SERVICIOS
            </a>
            <a className="nav-link" href="/#obra">
              OBRA
            </a>
            <a className="nav-link" href="/#contacto">
              CONTACTO
            </a>
          </div>
          <i
            type="button"
            onClick={handleClick}
            className={`bi ${clicked ? "bi-x" : "bi-list"} ${navbar ? "black-icon" : "white-icon"}`}
          ></i>
        </motion.div>
        <div ref={bgDiv} className="bg-div">
          <div ref={linksActive} className="links-active">
            <a onClick={handleClick} title="Empresa" href="/#home">
              INICIO
            </a>
            <a onClick={handleClick} title="Productos" href="/#servicios">
              SERVICIOS
            </a>
            <a onClick={handleClick} title="Productos" href="/#obra">
              OBRA
            </a>
            <a onClick={handleClick} title="Clientes" href="/#contacto">
              CONTACTO
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;