"use client";

import React from "react";
import { motion } from "framer-motion";
import DisenoArquitectonico from "../../media/DisenoArquitectonico.webp";
import DisenoDeLocales from "../../media/DisenoDeLocales.webp";
import PlanosMunicipales from "../../media/PlanosMunicipales.webp";
import Construccion from "../../media/Construccion.webp";
import "./Servicios.scss";

const services = [
  {
    title: "Diseño Arquitectónico",
    image: DisenoArquitectonico,
    description: [
      "Desarrollo de ideas y conceptos iniciales que se alineen con las necesidades y deseos del cliente.",
      "Recomendaciones sobre materiales, estilos y tecnologías sostenibles.",
    ],
  },
  {
    title: "Planos Municipales",
    image: PlanosMunicipales,
    description: [
      "Preparación de planos que cumplan con las normativas y regulaciones locales.",
      "Modificaciones de los planos según los requerimientos y observaciones de las entidades reguladoras.",
    ],
  },
  {
    title: "Diseño de Locales",
    image: DisenoDeLocales,
    description: [
      "Desarrollo de conceptos temáticos para la identidad del local.",
      "Asesoramiento en el cumplimiento de normativas y regulaciones comerciales.",
    ],
  },
  {
    title: "Construcción",
    image: Construccion,
    description: [
      "Supervisión y coordinación de todas las etapas del proyecto de construcción.",
      "Inspección y aseguramiento de la calidad de los trabajos realizados.",
    ],
  },
];

const Servicios = () => {
  return (
    <div id="servicios" className="services-container">
      {services.map((service, index) => (
        <motion.div
          key={index}
          className="service-card"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <div className="image-container">
            <img
              src={service.image}
              alt={service.title}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className="text-container">
            <h3>{service.title}</h3>
            <ul>
              {service.description.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default Servicios;
