"use client";

import React from "react";
import "./BotonFlotante.scss";
import { motion } from "framer-motion";

function BotonFlotante() {
  return (
    <div className="btn-flotante">
      <motion.a
        viewport={{ once: true }}
        initial={{ opacity: 0, scale: 0 }}
        whileInView={{
          opacity: 1,
          scale: 1,
          transition: { duration: 0.5 },
        }}
        whileHover={{ scale: 1.05 }}
        href="https://wa.me/5491159620493?text=Hola!%20Quería%20realizar%20la%20siguiente%20consulta..." 
        target="_blank"
        title="Escribir por WhatsApp"
      >
        <i className="bi bi-whatsapp boton-wsp"></i>
      </motion.a>
    </div>
  );
}

export default BotonFlotante;