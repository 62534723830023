"use client";

import "./Obra.scss";
import React from "react";
import { motion } from "framer-motion";
import obra1 from "../../media/OBRA1.webp";
import obra2 from "../../media/OBRA2.webp";
import obra3 from "../../media/OBRA3.webp";
import obra4 from "../../media/OBRA4.webp";
import obra5 from "../../media/OBRA5.webp";
import Slider from "react-slick";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
      },
    },
  ],
};

const trabajos = [
  {
    img: obra1,
  },
  {
    img: obra2,
  },
  {
    img: obra3,
  },
  {
    img: obra4,
  },
  {
    img: obra5,
  },
];

function Obra() {
  return (
    <div id="obra" className="contenedor-principal-Trabajos">
      <Slider className="elSlider" {...settings}>
        {trabajos.map((trabajo, index) => (
          <div key={index} className="trabajos">
            <div className="trabajo">
              <div className="imagen">
                <img src={trabajo.img} alt={`Trabajo ${index + 1}`} />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Obra;
