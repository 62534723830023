import logo from './logo.svg';
import './App.css';
import NavBar from "./components/Compartidos/Navbar/Navbar";
import BotonFlotante from "./components/Compartidos/BotonFlotante/BotonFlotante";
import HomeSlider from "./components/HomeSlider/HomeSlider";
import Servicios from "./components/Servicios/Servicios";
import Obra from "./components/Obra/Obra";
import Contacto from "./components/Contacto/Contacto";
import Footer from "./components/Compartidos/Footer/Footer";

function App() {
  return (
      <>
          <NavBar />
          <BotonFlotante />
          <HomeSlider />
          <Servicios />
          <Obra />
          <Contacto />
          <Footer />
      </>
  );
}

export default App;
