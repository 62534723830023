"use client";

import "./Footer.scss";
import React from "react";

function Footer() {
  return (
    <div className="detalleFooter">
      <p>
        © 2024, Estudio APPI, todos los derechos reservados. Construido por{" "}
        <a href="https://genovasite.com/">
          Agencia Genova de Desarrollo Web y Marketing
        </a>
        .
      </p>
    </div>
  );
}

export default Footer;
